import React, {Component, Suspense} from "react";
import {HashRouter, Route, Routes, BrowserRouter} from "react-router-dom";
import './assets/scss/style.scss';

const DefaultLayout = React.lazy(() => import("./pages/layout/DefaultLayout"));

class App extends Component {
  render() {
    return (
        <BrowserRouter>
          <Suspense>
            <DefaultLayout/>
          </Suspense>
        </BrowserRouter>
    );
  }
}

export default App;
